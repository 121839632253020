import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';
import { Organization } from '@/models/Organization';
import DashboardBureau from '@/components/dashboard-bureau/DashboardBureau.vue';
import { DateFormatter } from '@/support/DateFormatter';
import { DateTime } from 'luxon';

@Component<Dashboard>({
  components: {
    DashboardBureau,
  },
})
export default class Dashboard extends Vue {
  public $pageTitle = 'Dashboard';

  protected isLoading = true;

  // dates
  protected date = new DateFormatter();

  protected selectedDate: DateTime | null = null;

  // Organizations
  protected organizations: Organization[] | null = null;

  protected selectedOrganization: Organization | null = null;

  protected activeOrganization = '';

  protected mounted() {
    this.date.selectedDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.initBreadcrumb();
    this.emitSelectedDate();
    this.initialize();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }

  protected async initialize() {
    if (this.$store.state.isServiceOrganization) {
      await this.getOrganizations();
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
    }

    this.isLoading = false;
  }

  protected getOrganizations() {
    new Organization()
      .sort('created_at', 'ASC')
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (this.organizations.length && ! this.activeOrganization) {
          this.activeOrganization = this.organizations[0].id || '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected emitSelectedDate() {
    this.selectedDate = DateTime.fromSQL(this.date.selectedDate as string);
  }

  protected disableTab(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
