import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=21ebf28a&scoped=true&"
import script from "./Dashboard.ts?vue&type=script&lang=ts&"
export * from "./Dashboard.ts?vue&type=script&lang=ts&"
import style0 from "./dashboard.scss?vue&type=style&index=0&id=21ebf28a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ebf28a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21ebf28a')) {
      api.createRecord('21ebf28a', component.options)
    } else {
      api.reload('21ebf28a', component.options)
    }
    module.hot.accept("./Dashboard.vue?vue&type=template&id=21ebf28a&scoped=true&", function () {
      api.rerender('21ebf28a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ReportStatistics/Dashboard/Dashboard.vue"
export default component.exports